import { EventManager, Events } from '@/mixins/eventManager';

export default {
    name: 'WidgetGuideListCounter',
    data: () => {
        return {
            count: 0
        };
    },

    methods: {

        countAll: function(guide) {
            let amount = 0;

            if(guide !== undefined) {
                // count all full guides
                amount += guide.guides.filter((_guide) => {
                    return _guide.includeAll === true;
                }).length;

                // count all chapters
                guide.guides.filter((_guide) => {
                    if (_guide.includeAll === false) {
                        amount += _guide.chapters.length;
                    }
                    return true;
                });
            }

            this.count = amount;
        }
    },

    beforeMount(){
        EventManager.$on(Events.GUIDE_SAVED, this.countAll);
        EventManager.$on(Events.MY_GUIDE_LOADED, this.countAll);
    }
};
