import { Connector } from '@/mixins/connector';
import WidgetChapterShow from './widget-chapter-show';
import { Helpers } from '@/mixins/helpers';

export default {
    name: 'WidgetChapterShow',
    data: () => {
        return {
            uuid: null,
            chapter: null,
            section: null,
            scrollable: true
        };
    },
    components: {
        WidgetChapterShow
    },

    mounted() {
        if (this.$root.chapter_uuid) {
            this.uuid = this.$root.chapter_uuid;
            this.section = this.$root.section_uuid;

            Connector.methods.getChapter(this.uuid, chapter => {
                this.chapter = chapter;
            });
        }
    },

    updated() {
        if (this.scrollable) {
            Helpers.scrollToSection(this.section);
            this.scrollable = false;
        }
    }
};
