import { Helpers } from './helpers';
import { EventManager, Events } from './eventManager';
import { Connector } from './connector';

export const Storage = {
    data() {
        return {
            myGuide: null,
            activeGuide: 'hello'
        };
    },

    methods: {
        loadMyGuide() {
            if (sessionStorage.myGuide && sessionStorage.myGuide !== '') {
                this.myGuide = JSON.parse(sessionStorage.myGuide);
            } else {
                // this.resetGuide();
            }

            if (sessionStorage.myGuide && sessionStorage.myGuide !== '') {
                this.myGuide = JSON.parse(sessionStorage.myGuide);
                EventManager.$emit(Events.MY_GUIDE_LOADED, this.myGuide);
            } else {
                // this.resetGuide();
            }
        },

        getMyGuide() {
            return this.myGuide;
        },

        addChaptersToActiveGuide: function(guide, chapters) {
            let _guide = this.createGuideIfNonExisting(guide);
            _guide.title = guide.title;
            _guide.uuid = guide.uuid;
            _guide.tags = guide.tags;

            if (
                typeof _guide.chapters === 'undefined' ||
                _guide.chapters == null
            )
                _guide.chapters = [];

            _guide.includeAll = chapters.length === 0;
            _guide.chapters = chapters; //_guide.chapters.concat(chapters); // merge twee arrays

            this.addGuideByUuid(_guide, guide.uuid);
        },

        addGuideByUuid: function(guide, uuid) {
            let index = Helpers.getIndexOfMatchingUuid(
                this.myGuide.guides,
                uuid
            );
            this.myGuide.guides[index] = guide;
            this.saveMyGuide();
        },

        addGuideToActiveGuide: function(guide) {
            let chapters = [];
            this.addChaptersToActiveGuide(guide, chapters);
        },

        getGuide(uuid) {
            return Helpers.getGuidesByUuid(this.myGuide.guides, uuid);
        },

        createGuideIfNonExisting(guide) {
            if (this.myGuide === undefined || this.myGuide === null) {
                this.resetGuide();
                if (this.getGuide(guide.uuid).length === 0) {
                    this.myGuide.guides.push(Storage.TEMPLATE_GUIDE);
                }
            }

            if (
                this.myGuide.guides.length > 0 &&
                this.getGuide(guide.uuid).length > 0
            )
                return this.getGuide(guide.uuid)[0];
            else return Storage.TEMPLATE_GUIDE;
        },

        replaceMyGuide(guide) {
            if(this.myGuide !== undefined && this.myGuide !== null && guide !== undefined){
                this.myGuide = guide;
                this.saveMyGuide();
            }

            else
                this.loadMyGuide();
        },
        saveMyGuide() {
            if (sessionStorage)
                sessionStorage.myGuide = JSON.stringify(this.myGuide);

            EventManager.$emit(Events.GUIDE_SAVED, this.myGuide);
        },

        resetGuide: function() {

            if (!Storage.loadingGuide) {
                Storage.loadingGuide = true;

                this.myGuide = Storage.TEMPLATE_MYGUIDE;

                if (sessionStorage) sessionStorage.removeItem('myGuide');

                if (this.uuid === undefined || this.uuid == null) {
                    Connector.methods.createGuideUser(guide => {
                        this.myGuide.uuid = guide.uuid;
                        this.myGuide.title = guide.title;

                        this.saveMyGuide();
                        Storage.loadingGuide = false;
                        EventManager.$emit(
                            Events.MY_GUIDE_LOADED,
                            this.myGuide
                        );
                    });
                } else {
                    Connector.methods.getGuide(this.uuid, guide => {
                        this.myGuide.uuid = guide.uuid;
                        this.saveMyGuide();
                    });
                }
            }
        }
    }
};

Storage.TEMPLATE_MYGUIDE = {
    uuid: null,
    title: null,
    type: 'myGuide',
    guides: []
};
Storage.TEMPLATE_GUIDE = {
    uuid: null,
    title: null,
    type: 'guide',
    includeAll: false,
    tags: [],
    chapters: []
};
Storage.TEMPLATE_CHAPTER = { uuid: null, title: null, type: 'chapter' };

Storage.EXPORT_TYPE_EMAIL_SELF = 'email-self';
Storage.EXPORT_TYPE_EMAIL = 'email';
Storage.EXPORT_TYPE_PDF = 'pdf';

Storage.loadingGuide = false;
Storage.CONTENT_TYPE_GUIDE = 'guide';
Storage.CONTENT_TYPE_CHAPTER = 'chapter';
Storage.CONTENT_TYPE_PARAGRAPH = 'paragraph';
