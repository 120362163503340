export const Helpers = {

    exists: function(object){
        return object !== undefined && object !== null;
    },

    doesNotExist: function(object){
        return !(object !== undefined && object !== null);
    },

    removeDuplicates: function(array) {
        return array.filter(this.makeUnique);
    },

    filterArrayByKeyValue: function(array, property, value) {
        return array.filter(function(guide) {
            return guide[property] === value;
        });
    },

    makeUnique: function(value, index, self) {
        return self.indexOf(value) === index;
    },

    objectSize: function(obj) {
        var size = 0,
            key;
        for (key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) size++;
        }
        return size;
    },

    getIndexOfMatchingUuid(array, uuid) {
        let index = array.findIndex(obj => obj.uuid === uuid);
        return index === -1 ? array.length : index;
    },

    getGuidesByUuid: function(array, uuid) {
        return this.filterArrayByKeyValue(array, 'uuid', uuid);
    },

    getGetParamFromUrl(key){
        let url = new URL(window.location);
        return url.searchParams.get(key);
    },

    scrollToSection(id){
        let element = document.getElementById(id);
        if(element) element.scrollIntoView();
    },

    filterOutGuideByUuid(uuid, guides) {
        return guides.filter(function(guide) {
            return guide.uuid !== uuid;
        });
    },

    filterOutChapterFromGuideByUuid(chapterUuid, guideUuid, guides) {
        guides = guides.filter(function(guide) {
            if (guide.uuid === guideUuid) {
                guide.chapters = guide.chapters.filter(function(chapter) {
                    return chapter.uuid !== chapterUuid;
                });
            }
            return (guide.includeAll === false && guide.chapters.length > 0) || guide.includeAll;
        });

        return guides;
    },

    validateEmail(email)
    {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
            return true;
        }
        return false;
    }
};
