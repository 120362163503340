import { Storage } from '@/mixins/storage';
import { Connector } from '@/mixins/connector';
import {EventManager, Events} from "@/mixins/eventManager";
import {Helpers} from "@/mixins/helpers";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: 'WidgetGuideAdd',
    data: () => {
        return {
            uuid: null,
            open: false,
            openTooltip: false,
            guide: null,
            selection: false,
            checkedChapters: [],
            buttonLabel: 'Bewaar in Mijn Folder'
        };
    },

    components: {
        "font-awesome-icon": FontAwesomeIcon
    },
    methods: {
        addToGuide: function() {
            if (this.selection)
                Storage.methods.addChaptersToActiveGuide(
                    this.guide,
                    this.checkedChapters
                );
            else {
                if(this.guide !== null && this.guide !== undefined)
                    Storage.methods.addGuideToActiveGuide(this.guide);
            }
            this.buttonLabel = 'Bewaard!';
            this.toggle();
        },

        toggle: function() {
            this.open = !this.open;

            var self = this;

            setTimeout(function(){
                var modal = document.getElementsByClassName(self.open
                    ? 'widget-guide-add__options'
                    : 'widget-guide-add__button'
                )[0];
                modal.focus();
                modal.blur();
            }, 100);

            this.openTooltip = false;
        },

        toggleTooltip: function() {
            this.open = false;

            const self = this;

            setTimeout(function(){
                const modal = document.getElementsByClassName(self.openTooltip
                    ? 'widget-guide-add__tooltip'
                    : 'widget-guide-add__info-link'
                )[0];
                modal.focus();
                modal.blur();
            }, 100);

            this.openTooltip = !this.openTooltip;


        },

        updateGuide(){

            if(Helpers.exists(this.guide) && Helpers.exists( Storage.methods.getMyGuide())){

                let containingGuides = Helpers.getGuidesByUuid(Storage.methods.getMyGuide().guides, this.guide.uuid);
                this.buttonLabel = (Helpers.exists(containingGuides) && containingGuides.length > 0) ? 'Mijn Folder aanpassen' : 'Bewaar in Mijn Folder';
            }
        }
    },

    mounted() {

        if (this.$root.guide_uuid) {
            this.uuid = this.$root.guide_uuid;

            Connector.methods.getGuide(this.uuid, (guide) => {
                this.guide = guide;
                this.updateGuide(guide);
            });
        }
    },

    beforeMount() {
        EventManager.$on(Events.GUIDE_SAVED, this.updateGuide);
        EventManager.$on(Events.MY_GUIDE_LOADED, this.updateGuide);
    }
};
