import { Helpers } from '@/mixins/helpers';
import { Storage } from '@/mixins/storage';
import { EventManager, Events } from '@/mixins/eventManager';
import { Connector } from '@/mixins/connector';
import WidgetGuideListCounter from "../widget-guide-list-counter/widget-guide-list-counter";

export default {
    name: 'WidgetGuideList',
    data: () => {
        return {
            uuid: null,
            count: 0,
            guide: null,
            sending: false,
            toggled: false,
            return_url: null,
            closeIcon: null,
            modal: false,
            email: '',
            emailError: false,
            mailSend: false,
            exportType: Storage.EXPORT_TYPE_EMAIL_SELF,
            guideType: Storage.CONTENT_TYPE_GUIDE,
            chapterType: Storage.CONTENT_TYPE_CHAPTER
        };
    },
    components: {
        WidgetGuideListCounter
    },
    computed: {
        emailValidation: function () {
            return { success: this.validEmail(), warning: !this.validEmail() };
        }
    },
    methods: {
        onSave: function () {
            this.guide = Storage.methods.getMyGuide();
            this.updateGuide();

            if (this.$root.guide_user_uuid && !this.toggled) {
                this.toggled = true;
            }
        },

        countAll: function () {
            this.count = (this.guide !== undefined)
                ? Helpers.objectSize(this.guide.guides)
                : 0;
        },

        hideNotification: function() {
            this.mailSend = false;
        },

        onUserGuideLoaded: function (guide) {
            this.guide = guide;
        },

        updateGuide: function (callback) {
            if (this.guide !== undefined && this.guide) {
                if (this.guide.uuid !== undefined) {
                    Connector.methods.updateGuideUser(this.guide, response => {
                        if (callback !== undefined) {
                            callback(response);
                        }
                    });
                }
            }
        },

        toggleModal: function () {
            this.modal = !this.modal;

            if (this.modal) {
                setTimeout(function(){
                    var modal = document.getElementsByClassName('widget-guide-list__modal')[0];
                    modal.focus();
                    modal.blur();
                }, 100);

                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                EventManager.$emit(Events.TOGGLED_GUIDE_LIST, true);
            } else {
                setTimeout(function(){
                    var modal = document.getElementsByClassName('widget-guide-list-toggle')[0];
                    modal.focus();
                    modal.blur();
                });

                document.getElementsByTagName('body')[0].removeAttribute('style');
                EventManager.$emit(Events.TOGGLED_GUIDE_LIST, false);
            }
        },

        moveItemDown: function (index, type) {
            if (type === Storage.CONTENT_TYPE_GUIDE) {
                this.guide.guides.splice(
                    index + 2,
                    0,
                    Object.values(this.guide.guides)[index]
                );
                this.guide.guides.splice(index, 1);
                Storage.methods.replaceMyGuide(this.guide);
            }
        },
        moveItemUp: function (index, type) {
            if (type === Storage.CONTENT_TYPE_GUIDE) {
                this.guide.guides.splice(
                    index - 1,
                    0,
                    Object.values(this.guide.guides)[index]
                );
                this.guide.guides.splice(index + 1, 1);
                Storage.methods.replaceMyGuide(this.guide);
            }
        },

        onUserGuideSent: function () {
            this.sending = false;
        },

        onUserGuideSending: function () {
            this.sending = true;
        },

        removeGuide: function (uuid) {
            this.guide.guides = Helpers.filterOutGuideByUuid(
                uuid,
                this.guide.guides
            );
            Storage.methods.replaceMyGuide(this.guide);
        },

        removeChapterFromGuide(uuidChapter, uuidGuide) {
            this.guide.guides = Helpers.filterOutChapterFromGuideByUuid(
                uuidChapter,
                uuidGuide,
                this.guide.guides
            );
            Storage.methods.replaceMyGuide(this.guide);
        },

        exportGuide: function () {

            EventManager.$emit(Events.MY_GUIDE_SENDING, true);

            Storage.methods.saveMyGuide();

            let _self =  this;

            if (this.validEmail()) {

                this.updateGuide(() => {

                    switch (this.exportType) {
                        case Storage.EXPORT_TYPE_EMAIL.toString():
                            Connector.methods.exportGuideEmail(
                                this.guide.uuid,
                                false,
                                this.email,
                                this.return_url,
                                () => {
                                    // eslint-disable-next-line
                                    _self.mailSend = true;
                                }
                            );
                            break;
                        default:
                        case Storage.EXPORT_TYPE_EMAIL_SELF.toString():
                            Connector.methods.exportGuideEmail(
                                this.guide.uuid,
                                true,
                                this.email,
                                this.return_url,
                                () => {
                                    // eslint-disable-next-line
                                    _self.mailSend = true;
                                }
                            );
                            break;
                        case Storage.EXPORT_TYPE_PDF.toString():
                            Connector.methods.exportGuidePDF(
                                this.guide.uuid,
                                this.email,
                                () => {
                                    _self.mailSend = true;
                                }
                            );
                            break;
                    }
                });
                // Storage.methods.resetGuide();
            } else {

                this.emailError = true;
            }
        },
        validEmail: function () {
            return Helpers.validateEmail(this.email);
        }
    },

    mounted() {

        if (this.$root.guide_user_uuid && !this.guide) {
            Connector.methods.getGuideUser(
                this.$root.guide_user_uuid,
                response => {

                    Storage.methods.replaceMyGuide(response);

                    this.guide = Storage.methods.getMyGuide();

                }
            );
        } else {
            Storage.methods.loadMyGuide();
            var guide =  Storage.methods.getMyGuide();

            if(guide !== undefined)
                this.guide = guide;

        }

        if (this.$root.return_url) {
            this.return_url = this.$root.return_url;
        }

        if (this.$root.closeIcon) {
            this.closeIcon = this.$root.closeIcon;
        }

    },

    beforeMount() {

        EventManager.$on(Events.GUIDE_SAVED, this.onSave);
        EventManager.$on(Events.MY_GUIDE_LOADED, this.onSave);

        EventManager.$on(Events.GUIDE_SAVED, this.countAll);
        EventManager.$on(Events.MY_GUIDE_LOADED, this.countAll);

        EventManager.$on(Events.TOGGLE_GUIDE_LIST, this.toggleModal);
        EventManager.$on(Events.MY_GUIDE_LOADED, this.onUserGuideLoaded);
        EventManager.$on(Events.MY_GUIDE_SENT, this.onUserGuideSent);
        EventManager.$on(Events.MY_GUIDE_SENDING, this.onUserGuideSending);
    }
};
