import {Connector} from '@/mixins/connector';

export default {
    name: 'WidgetGuidesShow',
    data: () => {
        return {
            organisation: null,
            tags: null,
            guides: []
        };
    },
    methods: {
        formatTags: function (string) {
            string = string.replace(/\s*,\s*/g, ",");
            return string;
        }
    },
    beforeMount() {

        if (this.$root.tags && this.$root.organisation) {
            this.tags = this.formatTags(this.$root.tags);
            this.organisation = this.$root.organisation;

            Connector.methods.getGuidesByTags(this.tags, this.organisation, guides => {
                this.guides = guides;
            });
        }
    }
};
