import {Connector} from '@/mixins/connector';
import WidgetGuideShow from './widget-guide-show';
import {Helpers} from '@/mixins/helpers';
import VueScrollTo from "vue-scrollto";

export default {
    name: 'WidgetGuideShow',
    data: () => {
        return {
            uuid: null,
            guide: null,
            guide_sorted: null,
            response: null,
            section: null,
            scrollable: true,
            title: null,
            guide_user: false
        };
    },
    components: {
        WidgetGuideShow
    },

    methods: {
        sortGuideUser: function () {

            if (this.guide !== undefined && this.guide.contents !== undefined) {
                this.guide_sorted = {contents: {}};
                this.guide.contents.forEach((content) => {
                    if (content.rootOwner !== undefined) {
                        this.guide_sorted.contents[content.rootOwner.title] = (this.guide_sorted.contents[content.rootOwner.title] === undefined) ? [] : this.guide_sorted.contents[content.rootOwner.title];
                        this.guide_sorted.contents[content.rootOwner.title].push(content)
                    } else {
                        this.guide_sorted.contents[content.title] = (this.guide_sorted.contents[content.title] === undefined) ? [] : this.guide_sorted.contents[content.title];
                        this.guide_sorted.contents[content.title].push(content)
                    }

                });
            }
        }
    },

    mounted() {

        if (this.$root.guide_user_uuid) {
            this.guide_user = true;
            this.uuid = this.$root.guide_user_uuid;
            this.section = this.$root.section_uuid;

            Connector.methods.getGuide(this.$root.guide_user_uuid, guide => {
                if(guide !== undefined) {
                    this.guide = guide;
                    // only for user guide:
                    this.sortGuideUser();
                }
            });

        } else if (this.$root.guide_uuid) {
            this.guide_user = false;
            this.uuid = this.$root.guide_uuid;
            this.section = this.$root.section_uuid;

            Connector.methods.getGuide(this.$root.guide_uuid, guide => {
                if(guide !== undefined) {
                    this.guide = guide;
                }
            });
        }

    },

    updated() {
        if (this.scrollable) {
            Helpers.scrollToSection(this.section);
            this.scrollable = false;
        }

        if (this.$root.scroll) {

            VueScrollTo.scrollTo('#section-' + this.$root.scroll, {
                container: "body",
                duration: 500,
                easing: "ease",
                offset: 0,
                force: true,
                cancelable: true,
                onStart: false,
                onDone: false,
                onCancel: false,
                x: false,
                y: true
            });
        }
    }
};
