import WidgetGuideListCounter from '../widget-guide-list-counter/widget-guide-list-counter.vue';
import { EventManager, Events } from '@/mixins/eventManager';

export default {
    name: 'WidgetGuideListToggle',
    components: {
        WidgetGuideListCounter
    },
    data: () => {
        return {
            modal: false,
            iconClass: null,
            iconElement: null,
            label: 'Mijn Folder',
            labelClass: null,
            animating: false
        };
    },

    methods: {
        toggleModal: function(event) {
            this.modal = !this.modal;
            EventManager.$emit(Events.TOGGLE_GUIDE_LIST, event);
        },
        toggleTo: function(event){
            this.modal = event;
        },
        pulse: function() {
            let self = this;
            this.animating = true;
            let animateBG = this.$el.querySelector(
                '.widget-guide-list-toggle__animate'
            );
            animateBG.addEventListener(
                'animationend',
                function() {
                    self.animating = false;
                },
                false
            );
        }
    },

    mounted() {
        if (this.$root.label) {
            this.label = this.$root.label;
        }

        if (this.$root.iconClass) {
            this.iconClass = this.$root.iconClass;
        }

        if (this.$root.iconElement) {
            this.iconElement = this.$root.iconElement;
        }

        if (this.$root.labelClass) {
            this.labelClass = this.$root.labelClass;
        }
        
    },
    beforeMount() {
        EventManager.$on(Events.GUIDE_SAVED, this.pulse);
        EventManager.$on(Events.TOGGLED_GUIDE_LIST, this.toggleTo);
    }
};
