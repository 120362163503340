import axios from 'axios';
import {EventManager, Events} from "./eventManager";

export const Connector = {
    methods: {
        getParagraph(uuid, callback) {
            axios.get(process.env.VUE_APP_API_URL + 'paragraphs/' + uuid).then(response => {
                callback(response.data.data);
            });
        },
        getChapter(uuid, callback) {
            axios.get(process.env.VUE_APP_API_URL + 'chapters/' + uuid).then(response => {
                callback(response.data.data);
            });
        },
        getGuide(uuid, callback) {
            axios.get(process.env.VUE_APP_API_URL + 'guides/' + uuid).then(response => {
                callback(response.data.data);
            });
        },

        getGuides(uuid, callback) {
            axios.get(process.env.VUE_APP_API_URL + 'guides').then(response => {
                callback(response.data.data);
            });
        },

        getGuidesByTags(tags, organisationId, callback) {
            axios.get(process.env.VUE_APP_API_URL + 'guides/all', {params: {tags: tags, organisationId: organisationId, order: "title"}}).then(response => {
                callback(response.data.data);
            });
        },

        getGuideUser(uuid, callback) {
            axios.get(process.env.VUE_APP_API_URL + 'guides/user/' + uuid).then(response => {
                callback(response.data.data);
            });
        },

        createGuideUser(callback) {
            axios.post(process.env.VUE_APP_API_URL + 'guides/create', { title: "Mijn Folder" }).then(response => {
                callback(response.data.data);
            });
        },
        updateGuideUser(guide, callback) {
            axios.put(process.env.VUE_APP_API_URL + 'guides/' + guide.uuid, guide).then(response => {
                callback(response.data.data);
            });
        },
        exportGuideEmail(uuid, self, email, returnUrl, callback) {
            axios.post(process.env.VUE_APP_API_URL + 'guides/export/email/' + uuid, {email: email, type: self ? Connector.EXPORT_TYPE_INTERNAL : Connector.EXPORT_TYPE_EXTERNAL, message: "Mijn Folder", returnUrl: returnUrl
            })
            .then(response => {
                callback(response.data.data);
                EventManager.$emit(Events.MY_GUIDE_SENT, true);
            });
        },
        exportGuidePDF(uuid, email, callback) {
            axios.post(process.env.VUE_APP_API_URL + 'guides/export/pdf/' + uuid, {email: email, type: Connector.EXPORT_TYPE_INTERNAL}).then(response => {
                callback(response.data.data);
                EventManager.$emit(Events.MY_GUIDE_SENT, true);
            });
        }
    }
};
Connector.EXPORT_TYPE_DEFAULT = 'internal';
Connector.EXPORT_TYPE_INTERNAL = 'internal';
Connector.EXPORT_TYPE_EXTERNAL = 'external';
