import Vue from 'vue';
export const EventManager = new Vue();

export const Events  = {
    GUIDE_SAVED: "GUIDE_SAVED",
    MY_GUIDE_LOADED: "MY_GUIDE_LOADED",
    GUIDE_LOAD_LOCAL_REQUEST: "GUIDE_LOAD_LOCAL_REQUEST",
    MY_GUIDE_SENT: "MY_GUIDE_SENT",
    MY_GUIDE_SENDING: "MY_GUIDE_SENDING",
    TOGGLE_GUIDE_LIST: "TOGGLE_GUIDE_LIST",
    TOGGLED_GUIDE_LIST: "TOGGLED_GUIDE_LIST"
};
