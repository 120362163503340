import { Connector } from '@/mixins/connector';
import WidgetParagraphShow from './widget-paragraph-show';
import { Helpers } from '@/mixins/helpers';

export default {
    name: 'WidgetParagraphShow',
    data: () => {
        return {
            uuid: null,
            paragraph: null,
            section: null,
            scrollable: true
        };
    },
    components: {
        WidgetParagraphShow
    },

    mounted() {
        if (this.$root.paragraph_uuid) {
            this.uuid = this.$root.paragraph_uuid;
            this.section = this.$root.section_uuid;

            Connector.methods.getParagraph(this.uuid, paragraph => {
                this.paragraph = paragraph;
            });
        }
    },

    updated() {
        if (this.scrollable) {
            Helpers.scrollToSection(this.section);
            this.scrollable = false;
        }
    }
};
