import Vue from 'vue'
import 'promise-polyfill/src/polyfill';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faQuestionCircle,
    faTimes,
    faChevronRight,
    faArrowDown,
    faArrowUp,
    faTrashAlt,
    faBookOpen
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Connector } from '@/mixins/connector';
import { Storage } from '@/mixins/storage';
import WidgetGuideShow from './components/widget-guide-show/widget-guide-show.vue';
import WidgetGuidesShow from './components/widget-guides-show/widget-guides-show.vue';
import WidgetChapterShow from './components/widget-chapter-show/widget-chapter-show.vue';
import WidgetGuideAdd from './components/widget-guide-add/widget-guide-add.vue';
import WidgetParagraphShow from '@/components/widget-paragraph-show/widget-paragraph-show.vue';
import WidgetGuideList from './components/widget-guide-list/widget-guide-list.vue';
import WidgetGuideListToggle from './components/widget-guide-list-toggle/widget-guide-list-toggle.vue';
import { Helpers } from './mixins/helpers';
import VueScrollTo from 'vue-scrollto';

library.add(
    faQuestionCircle,
    faTimes,
    faChevronRight,
    faTrashAlt,
    faBookOpen,
    faArrowDown,
    faArrowUp
);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueScrollTo);

var BaseComponent = Vue.extend({
    props: [
        'guide_user_uuid',
        'return_url',
        'guide_uuid',
        'chapter_uuid',
        'scroll',
        'paragraph_uuid',
        'section_uuid',
        'apiUrl',
        'label',
        'closeIcon',
        'labelClass',
        'iconClass',
        'iconElement',
        'organisation',
        'tags'
    ],
    mixins: [Connector, Storage]
});

let widgetGuideShowElement = document.getElementById('widget-guide-show')

if(widgetGuideShowElement){
    new BaseComponent({
        el: widgetGuideShowElement,
        render: h => h(WidgetGuideShow),
        beforeMount() {
            // eslint-disable-next-line
            if (this.$el.getAttribute('data-guide_user_uuid') !== null) {
                this.$props['guide_user_uuid'] = this.$el.getAttribute('data-guide_user_uuid');
            }
            if (this.$el.getAttribute('data-uuid') !== null) {

                this.$props['guide_uuid'] = this.$el.getAttribute('data-uuid');
                this.$props['section_uuid'] = Helpers.getGetParamFromUrl('section');
            }

            if (this.$el.getAttribute('data-scroll') !== null) {
                this.$props['scroll'] = this.$el.getAttribute('data-scroll');
            }
        }
    });
}

let paragraphs = document.getElementsByClassName('widget-paragraph-show');
for (let el of paragraphs) {
    new BaseComponent({
        el: el,
        render: h => h(WidgetParagraphShow),
        beforeMount() {
            if (this.$el.getAttribute('data-data-uuid') !== null) {
                this.$props['data-paragraph_uuid'] = this.$el.getAttribute(
                    'data-data-uuid'
                );
                this.$props['section_uuid'] = Helpers.getGetParamFromUrl(
                    'section'
                );
            }
        }
    });
}

let chapters = document.getElementsByClassName('widget-chapter-show');
for (let el of chapters) {
    new BaseComponent({
        el: el,
        render: h => h(WidgetChapterShow),
        beforeMount() {
            if (this.$el.getAttribute('data-uuid') !== null) {
                this.$props['chapter_uuid'] = this.$el.getAttribute('data-uuid');
                this.$props['section_uuid'] = Helpers.getGetParamFromUrl(
                    'section'
                );
            }
        }
    });
}

// eslint-disable-next-line no-unused-vars
let tagsListsGuides = document.getElementsByClassName('widget-guides-show');
for (let el of tagsListsGuides) {
    new BaseComponent({
        el: el,
        render: h => h(WidgetGuidesShow),
        beforeMount() {
            if (this.$el.getAttribute('data-tags') !== null) {
                this.$props['tags'] = this.$el.getAttribute('data-tags');
            }
            if (this.$el.getAttribute('data-organisation') !== null) {
                this.$props['organisation'] = this.$el.getAttribute(
                    'data-organisation');
            }
        }
    });
}

let widgetGuideAdd = document.getElementById('widget-guide-add')

if(widgetGuideAdd) {
    new BaseComponent({
        el: widgetGuideAdd,
        render: h => h(WidgetGuideAdd),
        beforeMount() {
            if (this.$el.getAttribute('data-uuid') !== undefined)
                this.$props['guide_uuid'] = this.$el.getAttribute('data-uuid');
        }
    });
}

// eslint-disable-next-line no-unused-vars
let togglers = document.getElementsByClassName('widget-guide-list-toggle');
for (let el of togglers) {
    new BaseComponent({
        el: el,
        render: h => h(WidgetGuideListToggle),
        beforeMount() {
            if (this.$el.getAttribute('label') !== null) {
                this.$props['label'] = this.$el.getAttribute('label');
            }

            if (this.$el.getAttribute('labelClass') !== null) {
                this.$props['labelClass'] = this.$el.getAttribute('labelClass');
            }

            if (this.$el.getAttribute('iconClass') !== null) {
                this.$props['iconClass'] = this.$el.getAttribute('iconClass');
            }

            if (this.$el.getAttribute('iconElement') !== null) {
                this.$props['iconElement'] = this.$el.getAttribute('iconElement');
            }
        }
    });
}

let elements = document.getElementsByClassName('widget-guide-list');

for (let el of elements) {
    new BaseComponent({
        el: el,
        render: h => h(WidgetGuideList),
        beforeMount() {
            if (this.$el.getAttribute('data-guide_user_uuid') !== null) {
                this.$props['guide_user_uuid'] = this.$el.getAttribute(
                    'data-guide_user_uuid');
            }

            if (this.$el.getAttribute('data-return_url') !== null) {
                this.$props['return_url'] = this.$el.getAttribute(
                    'data-return_url');
            }

            if (this.$el.getAttribute('data-closeIcon') !== null) {
                this.$props['closeIcon'] = this.$el.getAttribute(
                    'data-closeIcon');
            }
        }
    });
}
